.Header {
  width: 99%;
  height: 100%;
  padding-bottom: 6px;
}

.Header-title {
  color: #8798ad;
  padding-top: 20px;
  padding-left: 30px;
}

.Header-field {
  margin-left: 30px;
}

.Header-field-description-row {
  padding: 0 35px;
  margin: 20px 0 !important;
}

.Header-field-finishing {
  margin-left: 23px;
}

.Header-field:first-of-type {
  margin-left: 40px;
}

.Header-boldText {
  font-weight: bold;
  margin-right: 5px;
}

.Header-button {
  float: right;
  margin-right: 30px;
}

.Header-button-center {
  transform: translateY(50%);
}

.Header-select-product {
  width: 312px;
}

.Header-select {
  width: 200px;
}

.Header-select-block {
  width: 100px;
}
