.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
  visibility: visible;
}

.row-dragging button {
  visibility: hidden;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.align-left {
  text-align: left !important;
  padding-left: 0.8em !important;
}