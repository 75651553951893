.Imposition {
  text-align: left;
  height: 100%;
}

.Imposition-table {
  width: 100%;
  height: 200px;
}

.Imposition-table-common {
  height: 100%;
  padding-left: 15px;
  border: 1px solid #cfd9f9;
}

.Imposition-table-row {
  height: 50px;
}

.Imposition-table-column1 {
  color: #b0bac9;
  width: 23%;
}

.Imposition-table-column2 {
  color: #b0bac9;
  width: 23%;
}

.Imposition-table-column3 {
  color: #b0bac9;
  width: 54%;
}

.Imposition-table-content {
  padding-top: 15px;
}
