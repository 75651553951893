.loading-spinner {
    background: rgba(0, 0, 0, 0.05);
}

.loading-spinner.full {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
}

.ant-spin-dot-spin {
    position: absolute;
    top: 50%;
    left: 50%;
}
